import React, { useState, useMemo, useRef, useEffect, useLayoutEffect, useCallback } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import ReactFullpage from '@fullpage/react-fullpage';

import FullpageSection from '@components/FullpageSection';
import FullpageCursor from '@components/FullpageCursor';
import ProjectInfo from '@components/ProjectInfo';

import { unflicker } from '@mixins';
import isSmall from '@utils/isSmall';
import isMedium from '@utils/isMedium';
import isBrowser from '@utils/isBrowser';

const pluginWrapper = () => {
  // eslint-disable-next-line global-require
  require('@static/fullpage.parallax.min');
};

const Fullpage = ({ sections }) => {
  const sectionAnchors = useMemo(() => sections.map(section => section.slugifiedTitle), [sections]);
  const sectionTitles = useMemo(() => sections.map(section => section.title), [sections]);

  const [cursorText, setCursorText] = useState({});
  const [currentSection, setCurrentSection] = useState(0);
  const containerRef = useRef();

  const handleSlideLeave = (section, origin, destination) => {
    if (isBrowser && window.fullpage_api) {
      window.fullpage_api.setLockAnchors(true);
    }

    setCursorText({
      index: destination.index + 1,
      length: sections[section.index].images.length,
    });
  };

  const handleLeave = (section, origin) => {
    if (isBrowser && window.fullpage_api) {
      window.fullpage_api.setLockAnchors(false);
    }

    setCurrentSection(origin.index);
    setCursorText({
      index: 1,
      length: sections[origin.index].images.length,
    });
  };

  const handleLoad = (section, origin) => {
    setCurrentSection(origin.index);
    setCursorText({
      index: 1,
      length: sections[origin.index].images.length,
    });
  };

  if (sections.length === 0) {
    return null;
  }

  return (
    <FullpageWrapper>
      {isSmall && <DisableScroll />}
      <div ref={containerRef}>
        <ReactFullpage
          // debug
          licenseKey="9KQQI-8M1PK-K2ARH-QJ3A7-CVJJM"
          pluginWrapper={pluginWrapper}
          controlArrows={false}
          // selectors
          slideSelector={!isSmall && '.fullpage-slide-image'}
          sectionSelector=".fullpage-section-project"
          // callbacks
          onSlideLeave={handleSlideLeave}
          afterLoad={handleLoad}
          onLeave={handleLeave}
          // navigation
          navigation={!isSmall || false}
          navigationPosition="left"
          // anchors
          anchors={sectionAnchors}
          navigationTooltips={!isSmall ? sectionTitles : []}
          // parallax options
          parallax
          parallaxKey="bDdiVzl1ZEdWd1lXTnJhR0Z0TG1OdmJRPT12bl85VUdjR0Z5WVd4c1lYZz00dHQ="
          parallaxOptions={{ type: 'cover', percentage: 90, property: 'translate' }}
          render={() => (
            <ReactFullpage.Wrapper>
              {sections.map((section, index) => (
                <FullpageSection key={index} {...section} />
              ))}
            </ReactFullpage.Wrapper>
          )}
        />
      </div>
      <ProjectInfo {...sections[currentSection]} />
      {!isMedium && <FullpageCursor containerRef={containerRef} cursorText={cursorText} />}
    </FullpageWrapper>
  );
};

const DisableScroll = createGlobalStyle`
  body {
    overflow: hidden;
  }
`;

const FullpageWrapper = styled.div`
  ${unflicker};
  background-color: var(--color-grey);
  overflow: hidden;
`;

export default Fullpage;
