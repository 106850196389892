import React from 'react';

import GlobalStyles from '@styles/GlobalStyles';
import Navigation from '@core/Navigation';
import Seo from '@core/Seo';
import { graphql, useStaticQuery } from 'gatsby';

const Layout = ({ children, location: { pathname } }) => {
  const {
    contentfulHomepage: { projects },
  } = useStaticQuery(graphql`
    query AllProjectsQuery {
      contentfulHomepage {
        projects {
          slugifiedTitle
        }
      }
    }
  `);

  return (
    <>
      <GlobalStyles />
      <Seo />
      <Navigation isHomepage={pathname === '/'} firstProject={projects[0].slugifiedTitle} />
      {children}
    </>
  );
};

export default Layout;
